import { useLogFatalError } from "@faire/retailer-visitor-shared/app/_lib/useLogFatalError";
import { FaireErrorBoundaryErrorContent } from "@faire/web--source/ui/FaireErrorBoundary/FaireErrorBoundary";
import React from "react";

const GlobalErrorBoundary: React.FC<{ error: Error }> = ({ error }) => {
  useLogFatalError(error);
  return <FaireErrorBoundaryErrorContent fullPage error={error} />;
};

export default GlobalErrorBoundary;
